<script setup lang="ts">
import { typeMap, type BucketFileId, type Variable } from '@respell/utils';
import Papa from 'papaparse';
import { z } from 'zod';

const { overwrite } = defineProps<{
  overwrite?: boolean;
}>();

const emit = defineEmits(['import']);

const spellStore = useSpellsStore();
const modal = useModal();
const groupId = useRouteParams('groupId');

const { liveGraph, spell } = storeToRefs(spellStore);

const bucketFileId = ref<BucketFileId | null>(null);
const headers = ref<string[]>([]);
const rows = ref<any[]>([]);
const form = ref(null);

const forbiddenTypes = [
  'file/video',
  'file/audio',
  'file/image',
  'file/spreadsheet',
  'file/document',
  'code',
  'object',
];

const mappingForm = reactiveComputed<Record<string, string | null>>(() => {
  if (!liveGraph.value?.inputs) return {};

  return Object.fromEntries(
    Object.entries(liveGraph.value.inputs)
      .filter(([, input]) => !input.metadata?.forReview)
      .map(([key]) => [key, null]),
  );
});

const schema = z.object(
  Object.fromEntries(
    Object.entries(liveGraph.value?.inputs ?? {}).map(([key, input]) => [
      key,
      input.isOptional
        ? z.string().nullable()
        : forbiddenTypes.includes(input.type)
          ? z.never({
              message: `${input.type} inputs are not available for bulk running.`,
            })
          : z.string({ message: 'This field is required' }),
    ]),
  ),
);

watchDeep(headers, async () => {
  try {
    await form.value?.validate();
  } catch (error) {
    // Intentionally empty to prevent throwing errors on validation
  }
});

watch(bucketFileId, async (newBucketFileId: BucketFileId | null) => {
  if (newBucketFileId) {
    const { ownerType, ownerId, workspaceId, fileId } = newBucketFileId;

    const { data: response } = await useApi(
      `/api/files/${fileId}?ownerType=${ownerType}&ownerId=${ownerId}&customerId=${workspaceId}`,
    );

    if (response.value) {
      const { presignedUrl } = response.value;

      Papa.parse(presignedUrl, {
        download: true,
        complete: parseComplete,
        skipEmptyLines: true,
        header: true,
      });
    }
  }
});

function parseComplete(results: any) {
  headers.value = results.meta.fields.map((field: string) => ({
    key: field,
    label: field,
    icon: 'i-ph-table',
  }));
  rows.value = results.data;
}

async function downloadTemplate() {
  if (!liveGraph.value) return;

  const headers = Object.keys(liveGraph.value.inputs);
  const csvContent = headers.join(',') + '\n';

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${spell.value?.name}_bulkTemplate.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

async function onImport() {
  const fileInputs = rows.value.map((row) => {
    const rowInput: Record<string, any> = {};
    for (const [key, value] of Object.entries({ ...mappingForm })) {
      rowInput[key] = row[value as string] ?? value;
    }
    return rowInput;
  });

  await spellStore.importGroupRuns(
    groupId.value as string,
    fileInputs,
    overwrite,
  );

  emit('import');
}
</script>
<template>
  <UModal>
    <UCard
      :ui="{
        base: 'w-full ',
        body: { base: 'max-h-[85vh] overflow-y-scroll' },
      }"
    >
      <UForm
        ref="form"
        :state="mappingForm"
        :schema="schema"
        class="flex flex-col w-full gap-5 items-start"
      >
        <p class="main-title">Import CSV</p>

        <AppFileInput
          v-if="spell"
          v-model="bucketFileId"
          owner-type="Spell"
          :owner-id="spell.id"
          :accepted-file-types="['text/csv']"
          :editable="true"
        />

        <div
          v-if="headers.length && Object.keys(liveGraph?.inputs ?? {}).length"
          class="w-full flex flex-col gap-4 items-start"
        >
          <UFormGroup
            size="lg"
            label="Now, map your inputs"
            name="formHeader"
            description="Select the column you want to use for each input"
            class="w-full"
          />

          <UFormGroup
            v-for="(input, key) in liveGraph?.inputs"
            :key="key"
            :name="key as string"
            :description="input.description"
            :required="!input.isOptional"
            :ui="{
              label: { base: 'flex gap-1 pb-3' },
              wrapper:
                'bg-primary-50 border border-gray-200 rounded-lg p-4 w-full',
            }"
          >
            <template #label>
              <UIcon
                :name="typeMap[input.type].icon"
                class="text-xl text-primary-500"
              />
              <p class="body text-primary-500">{{ input.name }}</p>
            </template>
            <USelectMenu
              v-model="mappingForm[key as string]"
              :options="[
                ...headers,
                ...(input.metadata?.options?.map((option: Variable) => ({
                  key: option.key,
                  label: option.name,
                  icon: 'i-ph-list-checks',
                })) ?? []),
              ]"
              value-attribute="key"
              size="lg"
              searchable
              :disabled="forbiddenTypes.includes(input.type)"
              placeholder="Select a column"
              class="w-full"
            />
          </UFormGroup>
        </div>

        <span v-else class="flex gap-1">
          <UIcon name="i-ph-info" color="gray" />
          <span class="body dimmed"
            >You can download a CSV template for this spell
            <UButton
              variant="link"
              :padded="false"
              label="here"
              size="xl"
              @click="downloadTemplate"
            />
          </span>
        </span>

        <div class="flex justify-end w-full gap-2 mt-m">
          <UButton
            size="xl"
            color="white"
            label="Cancel"
            @click="modal.close"
          />
          <UButton
            size="xl"
            label="Import"
            :disabled="form?.errors?.length || !headers.length"
            @click="onImport"
          />
        </div>
      </UForm>
    </UCard>
  </UModal>
</template>
