<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types';
import { z } from 'zod';

const modal = useModal();
const toast = useToast();

const schema = z.object({
  name: z.string().min(1),
});

type Schema = z.output<typeof schema>;

const spellStore = useSpellsStore();
const { createBulkGroup } = spellStore;

const state = reactive({
  name: '',
});

const onSubmit = async (event: FormSubmitEvent<Schema>) => {
  const {
    data: { name },
  } = event;

  await createBulkGroup({ name });
  modal.close();
};
</script>
<template>
  <UModal>
    <UForm
      :schema="schema"
      :state="state"
      class="w-full space-y-4"
      @submit="onSubmit"
    >
      <UCard
        :ui="{
          ring: '',
          divide: '',
        }"
      >
        <template #header>
          <div class="main-title">Create a new bulk run</div>
        </template>

        <div class="space-y-4">
          <UFormGroup label="Group name" name="name" required>
            <UInput v-model="state.name" placeholder="Bulk run name" />
          </UFormGroup>
        </div>

        <template #footer>
          <div class="flex justify-end space-x-2">
            <UButton type="button" size="xl" color="white" @click="modal.close"
              >Cancel
            </UButton>
            <UButton type="submit" size="xl">Confirm</UButton>
          </div>
        </template>
      </UCard>
    </UForm>
  </UModal>
</template>
